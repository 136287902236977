import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, Box, Snackbar, Alert, CircularProgress } from '@mui/material';
import WorriesForm from '../components/worries/WorriesForm';
import WorriesList from '../components/worries/WorriesList';
import { getWorriesEntries, clearError } from '../features/worries/worriesSlice';
import { useTranslation } from '../features/language/useTranslation';
import PageContainer from '../components/layout/PageContainer';

const WorriesPage = () => {
    const dispatch = useDispatch();
    const { entries, error, loading } = useSelector(state => state.worries);
    const [editEntry, setEditEntry] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const initializePage = async () => {
            try {
                await dispatch(getWorriesEntries()).unwrap();
            } catch (err) {
                console.error('Failed to load entries:', err);
            }
        };

        initializePage();
    }, [dispatch]);

    const handleCloseSnackbar = () => {
        dispatch(clearError());
    };

    if (loading) {
        return (
            <Container maxWidth="md">
                <Box sx={{ 
                    mt: 4, 
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px'
                }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <PageContainer>
            <Container maxWidth="md" sx={{ py: 4 }}>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: 4
                }}>
                    <Box>
                        <Typography 
                            variant="h4" 
                            component="h1" 
                            gutterBottom
                            sx={{ 
                                fontWeight: 600,
                                color: 'text.primary'
                            }}
                        >
                            {editEntry ? t('editWorry') : t('recordNewWorry')}
                        </Typography>
                        <WorriesForm editEntry={editEntry} setEditEntry={setEditEntry} />
                    </Box>

                    <Box>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2
                        }}>
                            <Typography 
                                variant="h5" 
                                component="h2"
                                sx={{ 
                                    fontWeight: 600,
                                    color: 'text.primary'
                                }}
                            >
                                {t('recentWorries')}
                            </Typography>
                        </Box>
                        <WorriesList onEdit={setEditEntry} />
                    </Box>

                    <Snackbar 
                        open={!!error} 
                        autoHideDuration={6000} 
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </PageContainer>
    );
};

export default WorriesPage;
