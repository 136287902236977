import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lastVisitedRoute: localStorage.getItem('lastVisitedRoute') || '/'
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setLastVisitedRoute: (state, action) => {
            state.lastVisitedRoute = action.payload;
            localStorage.setItem('lastVisitedRoute', action.payload);
        }
    }
});

export const { setLastVisitedRoute } = navigationSlice.actions;
export default navigationSlice.reducer;
