export const translations = {
    en: {
        // Navigation
        dashboard: 'Dashboard',
        mistakes: 'Mistakes',
        worries: 'Worries',
        profile: 'Profile',
        settings: 'Settings',
        logout: 'Logout',

        // Auth
        login: 'Login',
        register: 'Register',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        name: 'Name',
        forgotPassword: 'Forgot Password?',
        dontHaveAccount: "Don't have an account?",
        alreadyHaveAccount: 'Already have an account?',

        // Dashboard
        dashboardDescription: 'Track your worries and mistakes to improve personal growth',
        totalMistakes: 'Total Mistakes',
        totalWorries: 'Total Worries',
        analyze: 'Analyze',
        refresh: 'Refresh',
        lastAnalyzed: 'Last Analyzed',
        aiSuggestions: 'AI Suggestions',
        topMistakes: 'Top Mistakes',
        topWorries: 'Top Worries',
        categoryAnalysis: 'Category Analysis',
        noMistakes: 'No mistakes recorded yet',
        noWorries: 'No worries analysis available yet',
        addNew: 'Add New',
        viewAll: 'View All',
        worriesAnalysis: 'Worries Analysis',
        mistakesInsight: 'Mistakes Insight',
        recentActivity: 'Recent Activity',
        advice: 'Advice',

        // Mistakes Page
        recordNewMistake: 'Record New Mistake',
        editMistake: 'Edit Mistake',
        recentMistakes: 'Recent Mistakes',
        mistakeTitle: 'What happened?',
        mistakeDescription: 'Describe the mistake in detail',
        mistakeCategory: 'Category',
        mistakeLearning: 'What did you learn from this? (optional)',
        mistakeDate: 'When did it happen?',
        mistakeImpact: 'Impact Level',
        mistakeStatus: 'Current Status',
        saveMistake: 'Save Mistake',
        deleteMistake: 'Delete Mistake',
        mistakeDeleted: 'Mistake deleted successfully',
        mistakeSaved: 'Mistake saved successfully',
        dailyLimitReached: 'Daily Limit Reached',
        dailyLimitMessage: "You've recorded {count} mistakes today. Take some time to reflect and come back tomorrow.",

        // Worries Page
        recordNewWorry: 'Record New Worry',
        editWorry: 'Edit Worry',
        recentWorries: 'Recent Worries',
        worryTitle: "What's worrying you?",
        worryDescription: 'Describe your worry in detail',
        worryCategory: 'Category',
        worrySeverity: 'Severity Level',
        worryStatus: 'Current Status',
        saveWorry: 'Save Worry',
        deleteWorry: 'Delete Worry',
        worryDeleted: 'Worry deleted successfully',
        worrySaved: 'Worry saved successfully',
        addTag: 'Add Tag',
        tags: 'Tags',

        // Categories
        work: 'Work',
        personal: 'Personal',
        relationships: 'Relationships',
        communication: 'Communication',
        technical: 'Technical',
        organization: 'Organization',
        planning: 'Planning',
        health: 'Health',
        financial: 'Financial',
        education: 'Education',
        other: 'Other',

        // Common
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        edit: 'Edit',
        add: 'Add',
        description: 'Description',
        title: 'Title',
        date: 'Date',
        loading: 'Loading...',
        error: 'Error',
        success: 'Success',
        switchLanguage: 'Switch to Russian',
        category: 'Category',
        status: 'Status',
        actions: 'Actions',
        noData: 'No data available',
        today: 'Today',
        yesterday: 'Yesterday',
        lastWeek: 'Last Week',
        lastMonth: 'Last Month',
    },
    ru: {
        // Navigation
        dashboard: 'Главная',
        mistakes: 'Ошибки',
        worries: 'Переживания',
        profile: 'Профиль',
        settings: 'Настройки',
        logout: 'Выйти',

        // Auth
        login: 'Вход',
        register: 'Регистрация',
        email: 'Эл. почта',
        password: 'П��роль',
        confirmPassword: 'Подтвердите пароль',
        name: 'Имя',
        forgotPassword: 'Забыли пароль?',
        dontHaveAccount: 'Нет аккаунта?',
        alreadyHaveAccount: 'Уже есть аккаунт?',

        // Dashboard
        dashboardDescription: 'Отслеживайте свои переживания и ошибки для личностного роста',
        totalMistakes: 'Всего ошибок',
        totalWorries: 'Всего переживаний',
        analyze: 'Анализировать',
        refresh: 'Обновить',
        lastAnalyzed: 'Последний анализ',
        aiSuggestions: 'Предложения ИИ',
        topMistakes: 'Топ ошибок',
        topWorries: 'Топ переживаний',
        categoryAnalysis: 'Анализ по категориям',
        noMistakes: 'Пока нет записанных ошибок',
        noWorries: 'Пока нет анализа переживаний',
        addNew: 'Добавить',
        viewAll: 'Показать все',
        worriesAnalysis: 'Анализ переживаний',
        mistakesInsight: 'Анализ ошибок',
        recentActivity: 'Последние действия',
        advice: 'Совет',

        // Mistakes Page
        recordNewMistake: 'Записать новую ошибку',
        editMistake: 'Редактировать ошибку',
        recentMistakes: 'Последние ошибки',
        mistakeTitle: 'Что произошло?',
        mistakeDescription: 'Опишите ошибку подробно',
        mistakeCategory: 'Категория',
        mistakeLearning: 'Какой урок вы извлекли? (необязательно)',
        mistakeDate: 'Когда это произошло?',
        mistakeImpact: 'Уровень влияния',
        mistakeStatus: 'Текущий статус',
        saveMistake: 'Сохранить ошибку',
        deleteMistake: 'Удалить ошибку',
        mistakeDeleted: 'Ошибка успешно удалена',
        mistakeSaved: 'Ошибка успешно сохранена',
        dailyLimitReached: 'Достигнут дневной лимит',
        dailyLimitMessage: 'Вы записали {count} ошибок сегодня. Уделите время размышлениям и возвращайтесь завтра.',

        // Worries Page
        recordNewWorry: 'Записать новое переживание',
        editWorry: 'Редактировать переживание',
        recentWorries: 'Последние переживания',
        worryTitle: 'Что вас беспокоит?',
        worryDescription: 'Опишите ваше переживание подробно',
        worryCategory: 'Категория',
        worrySeverity: 'Уровень серьезности',
        worryStatus: 'Текущий статус',
        saveWorry: 'Сохранить переживание',
        deleteWorry: 'Удалить переживание',
        worryDeleted: 'Переживание успешно удалено',
        worrySaved: 'Переживание успешно сохранено',
        addTag: 'Добавить тег',
        tags: 'Теги',

        // Categories
        work: 'Работа',
        personal: 'Личное',
        relationships: 'Отношения',
        communication: 'Коммуникация',
        technical: 'Техническое',
        organization: 'Организация',
        planning: 'Планирование',
        health: 'Здоровье',
        financial: 'Финансы',
        education: 'Образование',
        other: 'Другое',

        // Common
        save: 'Сохранить',
        cancel: 'Отмена',
        delete: 'Удалить',
        edit: 'Редактировать',
        add: 'Добавить',
        description: 'Описание',
        title: 'Заголовок',
        date: 'Дата',
        loading: 'Загрузка...',
        error: 'Ошибка',
        success: 'Успешно',
        switchLanguage: 'Switch to English',
        category: 'Категория',
        status: 'Статус',
        actions: 'Действия',
        noData: 'Нет данных',
        today: 'Сегодня',
        yesterday: 'Вчера',
        lastWeek: 'Последняя неделя',
        lastMonth: 'Последний месяц',
    }
}; 