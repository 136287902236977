import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GoogleAuth from './GoogleAuth';
import {
    Container,
    Paper,
    Typography,
    Box,
    Alert
} from '@mui/material';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, loading } = useSelector(state => state.auth);
    const { lastVisitedRoute } = useSelector(state => state.navigation);

    useEffect(() => {
        if (isAuthenticated && !loading) {
            navigate(lastVisitedRoute || '/dashboard', { replace: true });
        }
    }, [isAuthenticated, loading, navigate, lastVisitedRoute]);

    return (
        <Container maxWidth="sm">
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
                    <Typography component="h1" variant="h5" align="center" gutterBottom>
                        Sign In
                    </Typography>

                    <Alert severity="warning" sx={{ mb: 3 }}>
                        Only Google Sign-In is available.
                    </Alert>

                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        width: '100%' 
                    }}>
                        <GoogleAuth />
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default Login;