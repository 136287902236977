import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWorriesEntry } from '../../features/worries/worriesSlice';
import {
    Box,
    Card,
    CardContent,
    Typography,
    IconButton,
    Grid,
    Chip,
    styled,
    CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from '../../features/language/useTranslation';

const moodColors = {
    'Happy': '#4CAF50',
    'Excited': '#8BC34A',
    'Calm': '#03A9F4',
    'Tired': '#9E9E9E',
    'Anxious': '#FFC107',
    'Sad': '#F44336',
    'Angry': '#FF5722',
    'Neutral': '#607D8B'
};

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: theme.shadows[4]
    }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(2),
    '&:last-child': {
        paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
    gap: theme.spacing(1),
}));

const StyledDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
}));

const StyledMood = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
}));

const StyledEntryText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: 1.6,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9375rem',
    },
}));

const StyledActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
}));

const WorriesList = ({ onEdit }) => {
    const dispatch = useDispatch();
    const { entries, loading } = useSelector(state => state.worries);
    const { t } = useTranslation();

    const handleDelete = async (id) => {
        try {
            await dispatch(deleteWorriesEntry(id)).unwrap();
        } catch (err) {
            console.error('Failed to delete entry:', err);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!entries || entries.length === 0) {
        return (
            <Box sx={{ textAlign: 'center', p: 3 }}>
                <Typography color="text.secondary">
                    {t('noWorries')}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                {t('recentWorries')}
            </Typography>
            {entries.map((entry) => (
                <StyledCard key={entry._id}>
                    <StyledCardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        {entry.title}
                                    </Typography>
                                    <Box>
                                        <IconButton 
                                            onClick={() => onEdit(entry)}
                                            size="small"
                                            sx={{ mr: 1 }}
                                            title={t('edit')}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton 
                                            onClick={() => handleDelete(entry._id)}
                                            size="small"
                                            color="error"
                                            title={t('delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <StyledEntryText
                                    variant="body2"
                                    sx={{
                                        mb: 2
                                    }}
                                >
                                    {entry.content}
                                </StyledEntryText>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                                    <Chip
                                        label={`${t('worrySeverity')}: ${entry.worriesLevel}/10`}
                                        size="small"
                                        variant="outlined"
                                    />
                                    {entry.tags && entry.tags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            size="small"
                                            variant="outlined"
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <StyledDate color="textSecondary" gutterBottom>
                                    {formatDistanceToNow(new Date(entry.date), { addSuffix: true })}
                                </StyledDate>
                            </Grid>
                        </Grid>
                    </StyledCardContent>
                </StyledCard>
            ))}
        </Box>
    );
};

export default WorriesList;
