// src/components/dashboard/MistakesInsight.js

import React from 'react';
import { 
    Box, 
    Paper, 
    Typography, 
    List, 
    ListItem, 
    ListItemText, 
    Chip, 
    Button, 
    Tooltip, 
    IconButton, 
    Alert, 
    CircularProgress 
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import { useTranslation } from '../../features/language/useTranslation';

const MistakesInsight = ({ 
    mistakes = [], 
    lastAnalyzed, 
    loading = false, 
    error = null,
    onRefresh,
    onClearError 
}) => {
    const { t } = useTranslation();

    return (
        <Paper sx={{ p: 2, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>
                    {t('mistakesInsight')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {lastAnalyzed && (
                        <Tooltip title={`${t('lastAnalyzed')}: ${format(new Date(lastAnalyzed), 'PPpp')}`}>
                            <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                                {t('lastAnalyzed')}: {formatDistanceToNow(new Date(lastAnalyzed))} ago
                            </Typography>
                        </Tooltip>
                    )}
                    <Tooltip title={t('refresh')}>
                        <IconButton 
                            onClick={onRefresh} 
                            disabled={loading}
                            sx={{ 
                                animation: loading ? 'spin 1s linear infinite' : 'none',
                                '@keyframes spin': {
                                    '0%': { transform: 'rotate(0deg)' },
                                    '100%': { transform: 'rotate(360deg)' }
                                }
                            }}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {error && (
                <Alert 
                    severity="error" 
                    onClose={onClearError} 
                    sx={{ 
                        mb: 2,
                        transition: 'all 0.3s ease-in-out'
                    }}
                >
                    {error}
                </Alert>
            )}

            <Box sx={{ 
                minHeight: 200,
                transition: 'all 0.3s ease-in-out'
            }}>
                {loading ? (
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        py: 4,
                        opacity: loading ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        <CircularProgress size={24} />
                        <Typography color="text.secondary" sx={{ ml: 2 }}>
                            {t('loading')}
                        </Typography>
                    </Box>
                ) : mistakes?.length > 0 ? (
                    <List sx={{ 
                        opacity: loading ? 0 : 1,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        {mistakes.map((insight, index) => (
                            <ListItem 
                                key={index} 
                                divider={index !== mistakes.length - 1}
                                sx={{ 
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        bgcolor: 'action.hover'
                                    }
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Chip
                                                label={`#${index + 1}`}
                                                color="primary"
                                                size="small"
                                                sx={{ mr: 1 }}
                                            />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                                {insight.theme}
                                            </Typography>
                                        </Box>
                                    }
                                    secondary={
                                        <Box sx={{ mt: 1 }}>
                                            <Typography 
                                                variant="body2" 
                                                color="primary" 
                                                sx={{ fontStyle: 'italic', mb: 1 }}
                                            >
                                                {t('advice')}: {insight.advice}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        py: 4,
                        opacity: loading ? 0 : 1,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        <Typography color="text.secondary">
                            {t('noMistakes')}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default MistakesInsight;