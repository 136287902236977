import React, { useEffect } from 'react';
import { 
    Box,
    List,
    ListItem,
    Typography,
    Chip,
    IconButton,
    Divider
} from '@mui/material';
import { 
    Edit as EditIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { format, differenceInHours } from 'date-fns';
import { getMistakes } from '../../features/mistakes/mistakeSlice';

const MistakeList = ({ onEdit }) => {
    const dispatch = useDispatch();
    const { mistakes, loading } = useSelector(state => state.mistakes);

    useEffect(() => {
        dispatch(getMistakes());
    }, [dispatch]);

    const canEditMistake = (mistakeDate) => {
        const hoursSinceCreation = differenceInHours(new Date(), new Date(mistakeDate));
        return hoursSinceCreation < 24;
    };

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', py: 2 }}>
                <Typography>Loading your mistakes...</Typography>
            </Box>
        );
    }

    if (!mistakes || mistakes.length === 0) {
        return (
            <Box sx={{ textAlign: 'center', py: 2 }}>
                <Typography>No mistakes recorded yet. Start by adding one!</Typography>
            </Box>
        );
    }

    const todaysMistakeCount = mistakes.filter(mistake => format(new Date(mistake.date), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')).length;
    const dailyLimitReached = todaysMistakeCount >= 6;

    return (
        <Box sx={{ mt: 2 }}>
            {loading ? (
                <Box sx={{ textAlign: 'center', py: 2 }}>
                    <Typography>Loading your mistakes...</Typography>
                </Box>
            ) : !mistakes || mistakes.length === 0 ? (
                <Box sx={{ textAlign: 'center', py: 2 }}>
                    <Typography>No mistakes recorded yet. Start by adding one!</Typography>
                </Box>
            ) : (
                <List sx={{ p: 0 }}>
                    {mistakes.map((mistake) => (
                        <ListItem
                            key={mistake._id}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                py: 2,
                                px: 0,
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                '&:last-child': {
                                    borderBottom: 'none'
                                }
                            }}
                        >
                            <Chip
                                label={mistake.category}
                                size="small"
                                sx={{
                                    mb: 1,
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    fontWeight: 500
                                }}
                            />
                            <Box sx={{ width: '100%' }}>
                                <Typography 
                                    sx={{ 
                                        fontWeight: 600,
                                        mb: 1,
                                        fontSize: '1.1rem',
                                        color: 'text.primary'
                                    }}
                                >
                                    {mistake.title}
                                </Typography>
                                <Typography 
                                    sx={{ 
                                        fontWeight: 400,
                                        mb: 1,
                                        fontSize: '1rem',
                                        color: 'text.primary'
                                    }}
                                >
                                    {mistake.description}
                                </Typography>
                                {mistake.aiAdvice && (
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="secondary" gutterBottom>
                                            AI Advice:
                                        </Typography>
                                        <Typography variant="body2">
                                            {mistake.aiAdvice}
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={{ mt: 2 }}>
                                    {mistake.lessonsLearned && (
                                        <Box sx={{ mb: 2 }}>
                                            <Typography 
                                                variant="subtitle2" 
                                                color="primary" 
                                                sx={{ fontWeight: 600 }}
                                            >
                                                Lessons Learned:
                                            </Typography>
                                            <Typography 
                                                variant="body2" 
                                                sx={{ mt: 1, color: 'text.primary' }}
                                            >
                                                {mistake.lessonsLearned}
                                            </Typography>
                                        </Box>
                                    )}
                                    {mistake.futureActions && (
                                        <Box sx={{ mb: 2 }}>
                                            <Typography 
                                                variant="subtitle2" 
                                                color="primary"
                                                sx={{ fontWeight: 600 }}
                                            >
                                                Future Actions:
                                            </Typography>
                                            <Typography 
                                                variant="body2" 
                                                sx={{ mt: 1, color: 'text.primary' }}
                                            >
                                                {mistake.futureActions}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                {mistake.reflection && (
                                    <Typography 
                                        sx={{ 
                                            color: 'text.secondary',
                                            fontSize: '0.9rem'
                                        }}
                                    >
                                        {mistake.reflection}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ 
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2
                            }}>
                                <Typography 
                                    sx={{ 
                                        fontSize: '0.75rem',
                                        color: 'text.secondary'
                                    }}
                                >
                                    {format(new Date(mistake.date), 'MMM d, yyyy')}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {canEditMistake(mistake.date) && (
                                        <IconButton
                                            onClick={() => onEdit(mistake)}
                                            size="small"
                                            sx={{ color: 'primary.main' }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default MistakeList;