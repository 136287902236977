import React from 'react';
import { 
    Box, 
    Paper, 
    Typography, 
    List, 
    ListItem, 
    ListItemText, 
    Chip, 
    Tooltip, 
    IconButton, 
    Alert, 
    CircularProgress 
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import { useTranslation } from '../../features/language/useTranslation';

const WorriesAnalysis = ({ 
    worries = [], 
    lastAnalyzed, 
    loading = false, 
    error = null,
    onRefresh,
    onClearError 
}) => {
    const { t } = useTranslation();

    return (
        <Paper sx={{ p: 2, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>
                    {t('worriesAnalysis')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {lastAnalyzed && (
                        <Tooltip title={`${t('lastAnalyzed')}: ${format(new Date(lastAnalyzed), 'PPpp')}`}>
                            <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                                {t('lastAnalyzed')}: {formatDistanceToNow(new Date(lastAnalyzed))} ago
                            </Typography>
                        </Tooltip>
                    )}
                    <Tooltip title={t('refresh')}>
                        <IconButton onClick={onRefresh} disabled={loading}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {error && (
                <Alert severity="error" onClose={onClearError} sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <CircularProgress size={24} />
                    <Typography color="text.secondary" sx={{ ml: 2 }}>
                        {t('loading')}
                    </Typography>
                </Box>
            ) : worries?.length > 0 ? (
                <List>
                    {worries.map((worry, index) => (
                        <ListItem key={index} divider={index !== worries.length - 1}>
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Chip
                                            label={`#${index + 1}`}
                                            color="primary"
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />
                                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                            {worry.theme || worry.title}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <Box sx={{ mt: 1 }}>
                                        <Typography 
                                            variant="body2" 
                                            color="primary" 
                                            sx={{ fontStyle: 'italic', mb: 1 }}
                                        >
                                            {t('advice')}: {worry.advice || worry.analysis}
                                        </Typography>
                                    </Box>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <Typography color="text.secondary">
                        {t('noWorries')}
                    </Typography>
                </Box>
            )}
        </Paper>
    );
};

export default WorriesAnalysis;