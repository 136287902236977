import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMistake, updateMistake } from '../../features/mistakes/mistakeSlice';
import {
    TextField,
    Button,
    Typography,
    Box,
    MenuItem,
    Grid
} from '@mui/material';
import { useTranslation } from '../../features/language/useTranslation';

const categories = [
    'Communication',
    'Technical',
    'Organization',
    'Planning',
    'Personal',
    'Work',
    'Health',
    'Financial',
    'Education',
    'Relationships',
    'Other',
];

const MistakeForm = ({ editMistake, setEditMistake }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        category: 'Other',
        lessonsLearned: '',
        futureActions: ''
    });

    const dispatch = useDispatch();
    const { dailyLimitReached, todaysMistakeCount } = useSelector(state => state.mistakes);

    useEffect(() => {
        if (editMistake) {
            setFormData({
                title: editMistake.title,
                description: editMistake.description,
                category: editMistake.category,
                lessonsLearned: editMistake.lessonsLearned || '',
                futureActions: editMistake.futureActions || ''
            });
        }
    }, [editMistake]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dailyLimitReached && !editMistake) {
            return;
        }

        if (editMistake) {
            dispatch(updateMistake({
                id: editMistake._id,
                mistakeData: formData
            }));
            setEditMistake(null);
        } else {
            dispatch(addMistake(formData));
        }

        setFormData({
            title: '',
            description: '',
            category: 'Other',
            lessonsLearned: '',
            futureActions: ''
        });
    };

    if (dailyLimitReached && !editMistake) {
        return (
            <Box sx={{
                textAlign: 'center',
                py: 2,
                color: 'warning.main'
            }}>
                <Typography variant="h6" gutterBottom>
                    {t('dailyLimitReached')}
                </Typography>
                <Typography>
                    {t('dailyLimitMessage', { count: todaysMistakeCount })}
                </Typography>
            </Box>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        name="category"
                        label={t('category')}
                        value={formData.category}
                        onChange={handleChange}
                        variant="outlined"
                    >
                        {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {t(category.toLowerCase())}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="title"
                        label={t('title')}
                        value={formData.title}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        placeholder={t('mistakeTitle')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        name="description"
                        label={t('mistakeDescription')}
                        value={formData.description}
                        onChange={handleChange}
                        variant="outlined"
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        label={t('mistakeLearning')}
                        name="lessonsLearned"
                        value={formData.lessonsLearned}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            py: 1.5,
                            fontSize: '1rem',
                            fontWeight: 600
                        }}
                    >
                        {editMistake ? t('editMistake') : t('saveMistake')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default MistakeForm;