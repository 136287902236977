import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import worriesReducer from './features/worries/worriesSlice';
import mistakesReducer from './features/mistakes/mistakeSlice';
import navigationReducer from './features/navigation/navigationSlice';
import themeReducer from './features/theme/themeSlice';
import languageReducer from './features/language/languageSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        mistakes: mistakesReducer,
        worries: worriesReducer,
        navigation: navigationReducer,
        theme: themeReducer,
        language: languageReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
});

export default store;