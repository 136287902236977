import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Configure axios base URL
axios.defaults.baseURL = '/api';

// Get worries entries
export const getWorriesEntries = createAsyncThunk(
    'worries/getEntries',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.get('/worries');
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to load entries');
        }
    }
);

// Add worries entry
export const addWorriesEntry = createAsyncThunk(
    'worries/addEntry',
    async(entryData, { rejectWithValue }) => {
        try {
            const res = await axios.post('/worries', entryData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to add entry');
        }
    }
);

// Update worries entry
export const updateWorriesEntry = createAsyncThunk(
    'worries/updateEntry',
    async({ id, entryData }, { rejectWithValue }) => {
        try {
            const res = await axios.put(`/worries/${id}`, entryData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to update entry');
        }
    }
);

// Delete worries entry
export const deleteWorriesEntry = createAsyncThunk(
    'worries/deleteEntry',
    async(id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`/worries/${id}`);
            return { _id: id };
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to delete entry');
        }
    }
);

// Get mood stats
export const getMoodStats = createAsyncThunk(
    'worries/getMoodStats',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.get('/worries/stats/mood');
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// Migrate diary entries to worries
export const migrateFromDiary = createAsyncThunk(
    'worries/migrateFromDiary',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.post('/worries/migrate');
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Migration failed');
        }
    }
);

// Get support for worries
export const getSupportForWorries = createAsyncThunk(
    'worries/getSupportForWorries',
    async(entry, { rejectWithValue }) => {
        try {
            return { support: null }; // Temporarily return null support
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to get support message');
        }
    }
);

// Get top worries
export const getTopWorries = createAsyncThunk(
    'worries/getTop',
    async(force = false, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/worries/top${force ? '?force=true' : ''}`);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to fetch top worries');
        }
    }
);

const initialState = {
    entries: [],
    topWorries: [],
    lastAnalyzed: null,
    loading: false,
    analyzing: false,
    error: null
};

const worriesSlice = createSlice({
    name: 'worries',
    initialState,
    reducers: {
        clearWorries: (state) => {
            state.entries = [];
            state.topWorries = [];
            state.lastAnalyzed = null;
            state.error = null;
        },
        clearError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        // Get worries entries
            .addCase(getWorriesEntries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getWorriesEntries.fulfilled, (state, action) => {
                state.loading = false;
                state.entries = action.payload;
            })
            .addCase(getWorriesEntries.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Add entry
            .addCase(addWorriesEntry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addWorriesEntry.fulfilled, (state, action) => {
                state.loading = false;
                state.entries.unshift(action.payload);
            })
            .addCase(addWorriesEntry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Update entry
            .addCase(updateWorriesEntry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateWorriesEntry.fulfilled, (state, action) => {
                state.loading = false;
                state.entries = state.entries.map(entry =>
                    entry._id === action.payload._id ? action.payload : entry
                );
            })
            .addCase(updateWorriesEntry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete entry
            .addCase(deleteWorriesEntry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteWorriesEntry.fulfilled, (state, action) => {
                state.loading = false;
                state.entries = state.entries.filter(entry => entry._id !== action.payload._id);
            })
            .addCase(deleteWorriesEntry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Get mood stats
            .addCase(getMoodStats.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getMoodStats.fulfilled, (state, action) => {
                state.loading = false;
                state.moodStats = action.payload;
            })
            .addCase(getMoodStats.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Get top worries
            .addCase(getTopWorries.pending, (state) => {
                state.analyzing = true;
                state.error = null;
            })
            .addCase(getTopWorries.fulfilled, (state, action) => {
                state.analyzing = false;
                state.topWorries = Array.isArray(action.payload) ? action.payload : [];
                state.lastAnalyzed = new Date().toISOString();
            })
            .addCase(getTopWorries.rejected, (state, action) => {
                state.analyzing = false;
                state.error = action.payload;
                state.topWorries = [];
            })
            // Migration
            .addCase(migrateFromDiary.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(migrateFromDiary.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(migrateFromDiary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Get support for worries
            .addCase(getSupportForWorries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSupportForWorries.fulfilled, (state, action) => {
                state.loading = false;
                // Do nothing with the support message
            })
            .addCase(getSupportForWorries.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { clearWorries, clearError } = worriesSlice.actions;

export default worriesSlice.reducer;