import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

// Please don't put /api/ in front of the route

// Load User
export const loadUser = createAsyncThunk(
    'auth/loadUser',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.get('/auth');
            return res.data;
        } catch (err) {
            localStorage.removeItem('token');
            return rejectWithValue(err.response?.data || { msg: 'Server Error' });
        }
    }
);

// Register User
export const register = createAsyncThunk(
    'auth/register',
    async(formData, { rejectWithValue }) => {
        try {
            const res = await axios.post('/users', formData);
            if (res.data.token) {
                localStorage.setItem('token', res.data.token);
                setAuthToken(res.data.token);
            }
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || { msg: 'Server Error' });
        }
    }
);

// Login User
export const login = createAsyncThunk(
    'auth/login',
    async(formData, { rejectWithValue }) => {
        try {
            const res = await axios.post('/auth', formData);
            if (res.data.token) {
                localStorage.setItem('token', res.data.token);
                setAuthToken(res.data.token);
            }
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || { msg: 'Server Error' });
        }
    }
);

// Google Auth
export const googleAuth = createAsyncThunk(
    'auth/googleAuth',
    async(credential, { rejectWithValue }) => {
        try {
            console.log('Sending Google credential:', credential);
            const res = await axios.post('/auth/google', { credential });
            if (res.data.token) {
                localStorage.setItem('token', res.data.token);
                setAuthToken(res.data.token);
            }
            return res.data;
        } catch (err) {
            console.error('Google Auth Error:', err.response?.data || err.message);
            return rejectWithValue(err.response?.data || { msg: 'Google Auth Error' });
        }
    }
);

// Update Profile
export const updateProfile = createAsyncThunk(
    'auth/updateProfile',
    async(formData, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            // Преобразуем формат данных для API
            const profileData = {
                displayName: formData.name,
                bio: formData.bio
            };

            const res = await axios.put('/profile', profileData, config);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Failed to update profile');
        }
    }
);

// Delete Account
export const deleteAccount = createAsyncThunk(
    'auth/deleteAccount',
    async(_, { rejectWithValue }) => {
        try {
            await axios.delete('/users');
            localStorage.removeItem('token');
            setAuthToken(null);
            return null;
        } catch (err) {
            return rejectWithValue(err.response?.data || { msg: 'Failed to delete account' });
        }
    }
);

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isLoading: true,
    user: null,
    error: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.token = null;
            state.isAuthenticated = false;
            state.isLoading = false;
            state.user = null;
            state.error = null;
            localStorage.removeItem('token');
            setAuthToken(null);
        },
        clearError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        // Load User
            .addCase(loadUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = true;
                state.user = action.payload;
                state.error = null;
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.user = null;
                state.token = null;
                state.error = action.payload?.msg || 'Failed to load user';
                localStorage.removeItem('token');
                setAuthToken(null);
            })
            // Register
            .addCase(register.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = true;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.error = null;
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.error = action.payload?.msg || 'Registration failed';
                localStorage.removeItem('token');
                setAuthToken(null);
            })
            // Login
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = true;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.error = action.payload?.msg || 'Login failed';
                localStorage.removeItem('token');
                setAuthToken(null);
            })
            // Google Auth
            .addCase(googleAuth.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(googleAuth.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = true;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.error = null;
            })
            .addCase(googleAuth.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.user = null;
                state.token = null;
                state.error = action.payload?.msg || 'Google authentication failed';
                localStorage.removeItem('token');
                setAuthToken(null);
            })
            // Update Profile
            .addCase(updateProfile.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = {
                    ...state.user,
                    ...action.payload,
                    name: action.payload.displayName || state.user.name
                };
                state.error = null;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.msg || 'Failed to update profile';
            })
            // Delete Account
            .addCase(deleteAccount.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteAccount.fulfilled, (state) => {
                state.token = null;
                state.isAuthenticated = false;
                state.isLoading = false;
                state.user = null;
                state.error = null;
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.msg || 'Failed to delete account';
            });
    }
});

export const { logout, clearError } = authSlice.actions;
export default authSlice.reducer;