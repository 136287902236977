import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const Navigation = () => {
  const { isAuthenticated } = useSelector(state => state.auth);

  return (
    <AppBar position="static" sx={{ width: '100%' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          FixFlow
        </Typography>
        {/* Здесь можно добавить дополнительные элементы навигации */}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation; 