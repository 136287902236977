import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import setAuthToken from './utils/setAuthToken';
import getTheme from './theme/theme';

// Components
import Layout from './components/layout/Layout';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import PrivateRoute from './components/routing/PrivateRoute';
import HomePage from './components/pages/HomePage';
import TermsOfService from './components/legal/TermsOfService';
import PrivacyPolicy from './components/legal/PrivacyPolicy';

// Pages
import MistakesPage from './pages/MistakesPage';
import ProfilePage from './pages/ProfilePage';
import WorriesPage from './pages/WorriesPage';
import DashboardPage from './pages/DashboardPage';

// Redux Slices
import { loadUser } from './features/auth/authSlice';

// Set base URL for axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL || '/api';

function App() {
    const dispatch = useDispatch();
    const mode = useSelector(state => state.theme.mode);
    const theme = getTheme(mode);

    useEffect(() => {
        // Check for token in localStorage and set up axios defaults
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token);
            // Dispatch loadUser and handle any errors
            dispatch(loadUser()).unwrap()
                .catch(error => {
                    console.error('Failed to load user:', error);
                    localStorage.removeItem('token');
                    setAuthToken(null);
                });
        }
    }, [dispatch]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Router>
                    <Layout>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/terms-of-service" element={<TermsOfService />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route element={<PrivateRoute />}>
                                <Route path="/dashboard" element={<DashboardPage />} />
                                <Route path="/mistakes" element={<MistakesPage />} />
                                <Route path="/worries" element={<WorriesPage />} />
                                <Route path="/profile" element={<ProfilePage />} />
                            </Route>
                        </Routes>
                    </Layout>
                </Router>
            </GoogleOAuthProvider>
        </ThemeProvider>
    );
}

export default App;