import React from 'react';
import { Box } from '@mui/material';

const PageContainer = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100vw',
        minHeight: '100vh',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          padding: (theme) => theme.spacing(3),
          maxWidth: '100%'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageContainer; 