import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    addWorriesEntry,
    updateWorriesEntry
} from '../../features/worries/worriesSlice';
import {
    Box,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slider,
    Typography,
    Chip,
    Grid,
    Stack
} from '@mui/material';
import { useTranslation } from '../../features/language/useTranslation';

const WorriesForm = ({ editEntry, setEditEntry }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        worriesLevel: 5,
        tags: []
    });
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        if (editEntry) {
            setFormData({
                title: editEntry.title || '',
                content: editEntry.content || '',
                worriesLevel: editEntry.worriesLevel || 5,
                tags: editEntry.tags || []
            });
        }
    }, [editEntry]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSliderChange = (event, newValue) => {
        setFormData({
            ...formData,
            worriesLevel: newValue
        });
    };

    const handleAddTag = (e) => {
        e.preventDefault();
        if (newTag.trim() && !formData.tags.includes(newTag.trim())) {
            setFormData({
                ...formData,
                tags: [...formData.tags, newTag.trim()]
            });
            setNewTag('');
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        setFormData({
            ...formData,
            tags: formData.tags.filter(tag => tag !== tagToRemove)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editEntry) {
                await dispatch(updateWorriesEntry({
                    id: editEntry._id,
                    entryData: formData
                })).unwrap();
                setEditEntry(null);
            } else {
                await dispatch(addWorriesEntry(formData)).unwrap();
            }
            setFormData({
                title: '',
                content: '',
                worriesLevel: 5,
                tags: []
            });
        } catch (err) {
            console.error('Failed to save entry:', err);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('worryTitle')}
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('worryDescription')}
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography gutterBottom>
                        {t('worrySeverity')}
                    </Typography>
                    <Slider
                        value={formData.worriesLevel}
                        onChange={handleSliderChange}
                        min={1}
                        max={10}
                        step={1}
                        marks
                        valueLabelDisplay="auto"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                            label={t('addTag')}
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            size="small"
                        />
                        <Button onClick={handleAddTag} variant="outlined" size="small">
                            {t('add')}
                        </Button>
                    </Stack>
                    <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {formData.tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                onDelete={() => handleRemoveTag(tag)}
                            />
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {editEntry ? t('editWorry') : t('saveWorry')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WorriesForm;