import React from 'react';
import { Box, Paper, Typography, LinearProgress } from '@mui/material';
import { useTranslation } from '../../features/language/useTranslation';

const CategoryStats = ({ mistakes }) => {
    const { t } = useTranslation();

    // Calculate category statistics
    const categoryStats = mistakes.reduce((acc, mistake) => {
        acc[mistake.category] = (acc[mistake.category] || 0) + 1;
        return acc;
    }, {});

    // Convert to array and sort by count
    const sortedStats = Object.entries(categoryStats)
        .sort(([, a], [, b]) => b - a)
        .map(([category, count]) => ({
            category,
            count,
            percentage: (count / mistakes.length) * 100
        }));

    return (
        <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
                {t('categoryAnalysis')}
            </Typography>
            {sortedStats.length > 0 ? (
                <Box sx={{ mt: 2 }}>
                    {sortedStats.map(({ category, count, percentage }) => (
                        <Box key={category} sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" color="text.secondary">
                                    {t(category.toLowerCase()) || category}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {count} ({percentage.toFixed(1)}%)
                                </Typography>
                            </Box>
                            <LinearProgress 
                                variant="determinate" 
                                value={percentage} 
                                sx={{ 
                                    height: 8,
                                    borderRadius: 4
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <Typography color="text.secondary">
                        {t('noData')}
                    </Typography>
                </Box>
            )}
        </Paper>
    );
};

export default CategoryStats;
