import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, BottomNavigation as MuiBottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home, BugReport, Psychology, AccountCircle } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../features/language/useTranslation';

const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { t } = useTranslation();

  if (!isAuthenticated) return null;

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        zIndex: 1000,
        paddingBottom: 'env(safe-area-inset-bottom)'
      }} 
      elevation={3}
    >
      <MuiBottomNavigation
        value={location.pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction 
          label={t('dashboard')}
          value="/dashboard" 
          icon={<Home />} 
        />
        <BottomNavigationAction 
          label={t('mistakes')}
          value="/mistakes" 
          icon={<BugReport />} 
        />
        <BottomNavigationAction 
          label={t('worries')}
          value="/worries" 
          icon={<Psychology />} 
        />
        <BottomNavigationAction 
          label={t('profile')}
          value="/profile"
          icon={<AccountCircle />} 
        />
      </MuiBottomNavigation>
    </Paper>
  );
};

export default BottomNavigation; 