import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Paper, 
  Typography, 
  Box, 
  Button, 
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  useTheme
} from '@mui/material';
import { 
  Logout, 
  Translate,
  AccountCircle
} from '@mui/icons-material';
import { logout } from '../features/auth/authSlice';
import { toggleLanguage } from '../features/language/languageSlice';
import { useTranslation } from '../features/language/useTranslation';
import PageContainer from '../components/layout/PageContainer';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const { language } = useSelector(state => state.language);

  console.log('Profile Page Render:', { user, language, theme });

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleLanguageToggle = () => {
    dispatch(toggleLanguage());
  };

  if (!user) {
    return (
      <PageContainer>
        <Container maxWidth="sm" sx={{ py: 4 }}>
          <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
            <Typography>Loading user data...</Typography>
          </Paper>
        </Container>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Paper 
          elevation={3}
          sx={{ 
            p: 3,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2
          }}
        >
          {/* User Info Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <AccountCircle 
              sx={{ 
                width: 64, 
                height: 64, 
                mr: 2, 
                color: theme.palette.primary.main 
              }} 
            />
            <Box>
              <Typography 
                variant="h5" 
                component="h1" 
                gutterBottom
                sx={{ color: theme.palette.text.primary }}
              >
                {user?.name || t('profile')}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ color: theme.palette.text.secondary }}
              >
                {user?.email}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Settings List */}
          <List sx={{ width: '100%' }}>
            {/* Language Toggle */}
            <ListItem sx={{ py: 2 }}>
              <ListItemIcon>
                <Translate sx={{ color: theme.palette.primary.main }} />
              </ListItemIcon>
              <ListItemText 
                primary={t('language')}
                secondary={language === 'en' ? t('english') : t('russian')}
                primaryTypographyProps={{
                  sx: { color: theme.palette.text.primary }
                }}
                secondaryTypographyProps={{
                  sx: { color: theme.palette.text.secondary }
                }}
              />
              <Switch
                edge="end"
                checked={language === 'ru'}
                onChange={handleLanguageToggle}
                color="primary"
              />
            </ListItem>

            <Divider sx={{ my: 1 }} />

            {/* Logout Button */}
            <ListItem sx={{ py: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                color="error"
                startIcon={<Logout />}
                onClick={handleLogout}
                sx={{ 
                  py: 1,
                  borderRadius: 1,
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                {t('logout')}
              </Button>
            </ListItem>
          </List>
        </Paper>
      </Container>
    </PageContainer>
  );
};

export default ProfilePage;