import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => createTheme({
    palette: {
        mode,
        ...(mode === 'light' ? {
            // Light mode colors - newspaper style
            primary: {
                main: '#4A4A4A', // Muted dark grey
                light: '#6B6B6B',
                dark: '#2C2C2C',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#8B7355', // Warm brown
                light: '#A68B6E',
                dark: '#6F5B3E',
                contrastText: '#ffffff',
            },
            success: {
                main: '#5B8A72', // Muted green
                light: '#77A090',
                dark: '#436B57',
                contrastText: '#ffffff',
            },
            error: {
                main: '#B4656F',
                light: '#C88891',
                dark: '#914851',
            },
            warning: {
                main: '#C4A484', // Warm beige
                light: '#D6BFA6',
                dark: '#A68B6E',
            },
            info: {
                main: '#7B92A6', // Muted blue-grey
                light: '#99AAB9',
                dark: '#5D7A8C',
            },
            background: {
                default: '#F5F5F0', // Warm off-white
                paper: '#FFFFFF',
                gradient: 'linear-gradient(135deg, #4A4A4A 0%, #6B6B6B 35%, #8B7355 70%, #A68B6E 100%)',
                gradientAlt: 'linear-gradient(45deg, #2C2C2C 0%, #4A4A4A 35%, #6F5B3E 70%, #8B7355 100%)',
            },
            text: {
                primary: '#2C2C2C', // Dark grey
                secondary: '#4A4A4A',
                disabled: '#9ca3af',
            },
        } : {
            // Dark mode colors - newspaper style
            primary: {
                main: '#D6D6D6', // Light grey
                light: '#E8E8E8',
                dark: '#BDBDBD',
                contrastText: '#1A1A1A',
            },
            secondary: {
                main: '#C4A484', // Warm beige
                light: '#D6BFA6',
                dark: '#A68B6E',
                contrastText: '#1A1A1A',
            },
            success: {
                main: '#77A090', // Muted green
                light: '#94B5A8',
                dark: '#5B8A72',
                contrastText: '#1A1A1A',
            },
            error: {
                main: '#C88891',
                light: '#D6A6AC',
                dark: '#B4656F',
            },
            warning: {
                main: '#D6BFA6',
                light: '#E8D6C4',
                dark: '#C4A484',
            },
            info: {
                main: '#99AAB9',
                light: '#B3C2CC',
                dark: '#7B92A6',
            },
            background: {
                default: '#1A1A1A', // Dark grey
                paper: '#2C2C2C',
                gradient: 'linear-gradient(135deg, #BDBDBD 0%, #D6D6D6 35%, #A68B6E 70%, #C4A484 100%)',
                gradientAlt: 'linear-gradient(45deg, #BDBDBD 0%, #D6D6D6 35%, #A68B6E 70%, #C4A484 100%)',
            },
            text: {
                primary: '#F5F5F0', // Off-white
                secondary: '#E8E8E8',
                disabled: '#9ca3af',
            },
        }),
        divider: mode === 'light' ? 'rgba(44, 44, 44, 0.08)' : 'rgba(245, 245, 240, 0.08)',
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'SF Pro Display',
            'SF Pro Text',
            'BlinkMacSystemFont',
            'Helvetica Neue',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: {
            fontWeight: 600,
            letterSpacing: '-0.025em',
            fontSize: '2.5rem',
        },
        h2: {
            fontWeight: 600,
            letterSpacing: '-0.025em',
            fontSize: '2rem',
        },
        h3: {
            fontWeight: 500,
            letterSpacing: '-0.025em',
            fontSize: '1.75rem',
        },
        h4: {
            fontWeight: 500,
            letterSpacing: '-0.025em',
            fontSize: '1.5rem',
        },
        h5: {
            fontWeight: 500,
            fontSize: '1.25rem',
        },
        h6: {
            fontWeight: 500,
            fontSize: '1rem',
        },
        subtitle1: {
            letterSpacing: 0,
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        subtitle2: {
            letterSpacing: 0,
            fontSize: '0.875rem',
            lineHeight: 1.5,
        },
        body1: {
            letterSpacing: 0,
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        body2: {
            letterSpacing: 0,
            fontSize: '0.875rem',
            lineHeight: 1.5,
        },
    },
    shape: {
        borderRadius: 12,
    },
    shadows: [
        'none',
        '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)',
        '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
        '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        ...Array(18).fill('none'),
    ],
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '8px 16px',
                    borderRadius: '8px',
                },
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                outlined: {
                    borderWidth: '1.5px',
                    '&:hover': {
                        borderWidth: '1.5px',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: mode === 'light' 
                        ? '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)'
                        : '0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 2px -1px rgba(0, 0, 0, 0.3)',
                    borderRadius: 16,
                    padding: '24px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 8,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: 8,
                    ...(theme.palette.mode === 'dark' && {
                        '&.MuiChip-filled': {
                            color: '#1A1A1A',
                            backgroundColor: '#D6D6D6',
                        },
                        '&.MuiChip-outlined': {
                            color: '#1A1A1A',
                            backgroundColor: '#D6D6D6',
                            borderColor: '#1A1A1A',
                        },
                    }),
                }),
            },
        },
    },
});

export default getTheme;
