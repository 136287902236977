import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Button,
    Grid,
    Paper,
    useTheme
} from '@mui/material';
import {
    TrendingUp as TrendingUpIcon,
    Psychology as PsychologyIcon,
    EmojiEvents as AchievementsIcon
} from '@mui/icons-material';

const FeatureCard = ({ icon, title, description }) => {
    const theme = useTheme();
    return ( <
        Paper elevation = { 3 }
        sx = {
            {
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: theme.shadows[6]
                }
            }
        } >
        <
        Box sx = {
            { p: 2, color: theme.palette.primary.main } } > { icon } <
        /Box> <
        Typography variant = "h6"
        component = "h3"
        gutterBottom > { title } <
        /Typography> <
        Typography variant = "body1"
        color = "text.secondary" > { description } <
        /Typography> <
        /Paper>
    );
};

const HomePage = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const features = [{
            icon: < TrendingUpIcon sx = {
                { fontSize: 40 } }
            />,
            title: "Track Progress",
            description: "Monitor your personal growth and development through comprehensive tracking and analytics."
        },
        {
            icon: < PsychologyIcon sx = {
                { fontSize: 40 } }
            />,
            title: "Learn from Mistakes",
            description: "Transform setbacks into opportunities by recording and reflecting on your mistakes."
        },
        {
            icon: < AchievementsIcon sx = {
                { fontSize: 40 } }
            />,
            title: "Celebrate Achievements",
            description: "Document and celebrate your successes, both big and small, to stay motivated."
        }
    ];

    return ( <
        Box sx = {
            {
                minHeight: '100vh',
                background: `linear-gradient(45deg, ${theme.palette.primary.main}15, ${theme.palette.secondary.main}15)`,
                pt: 8
            }
        } >
        <
        Container maxWidth = "lg" > { /* Hero Section */ } <
        Box sx = {
            { textAlign: 'center', mb: 8 } } >
        <
        Typography variant = "h2"
        component = "h1"
        gutterBottom sx = {
            {
                fontWeight: 700,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                backgroundClip: 'text',
                textFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }
        } >
        Welcome to FixFlow <
        /Typography> <
        Typography variant = "h5"
        color = "text.secondary"
        sx = {
            { mb: 4, maxWidth: '800px', mx: 'auto' } } >
        Your personal growth companion that helps you learn from mistakes and celebrate achievements on your journey to success. <
        /Typography> <
        Box sx = {
            { display: 'flex', gap: 2, justifyContent: 'center' } } >
        <
        Button variant = "contained"
        size = "large"
        onClick = {
            () => navigate('/register') }
        sx = {
            {
                borderRadius: 2,
                textTransform: 'none',
                px: 4,
                py: 1.5
            }
        } >
        Get Started <
        /Button> <
        Button variant = "outlined"
        size = "large"
        onClick = {
            () => navigate('/login') }
        sx = {
            {
                borderRadius: 2,
                textTransform: 'none',
                px: 4,
                py: 1.5
            }
        } >
        Sign In <
        /Button> <
        /Box> <
        /Box>

        { /* Features Section */ } <
        Grid container spacing = { 4 }
        sx = {
            { mb: 8 } } > {
            features.map((feature, index) => ( <
                Grid item xs = { 12 }
                md = { 4 }
                key = { index } >
                <
                FeatureCard {...feature }
                /> <
                /Grid>
            ))
        } <
        /Grid>

        { /* Call to Action */ } <
        Box sx = {
            {
                textAlign: 'center',
                py: 8,
                borderRadius: 4,
                bgcolor: 'background.paper',
                boxShadow: 1,
                mb: 8
            }
        } >
        <
        Typography variant = "h4"
        gutterBottom >
        Ready to Start Your Journey ?
        <
        /Typography> <
        Typography variant = "body1"
        color = "text.secondary"
        sx = {
            { mb: 4, maxWidth: '600px', mx: 'auto' } } >
        Join FixFlow today and take the first step towards mindful personal development. <
        /Typography> <
        Button variant = "contained"
        size = "large"
        onClick = {
            () => navigate('/register') }
        sx = {
            {
                borderRadius: 2,
                textTransform: 'none',
                px: 4,
                py: 1.5
            }
        } >
        Create Your Account <
        /Button> <
        /Box> <
        /Container> <
        /Box>
    );
};

export default HomePage;