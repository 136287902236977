import { createSlice } from '@reduxjs/toolkit';

const translations = {
    en: {
        dashboard: 'Dashboard',
        mistakes: 'Mistakes',
        worries: 'Worries',
        profile: 'Profile',
        language: 'Language',
        logout: 'Log Out',
        settings: 'Settings',
        english: 'English',
        russian: 'Russian',
        // ... другие переводы
    },
    ru: {
        dashboard: 'Панель',
        mistakes: 'Ошибки',
        worries: 'Заботы',
        profile: 'Профиль',
        language: 'Язык',
        logout: 'Выйти',
        settings: 'Настройки',
        english: 'Английский',
        russian: 'Русский',
        // ... другие переводы
    }
};

const initialState = {
    language: localStorage.getItem('language') || 'en',
    translations
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        toggleLanguage: (state) => {
            state.language = state.language === 'en' ? 'ru' : 'en';
            localStorage.setItem('language', state.language);
        }
    }
});

export const { toggleLanguage } = languageSlice.actions;
export default languageSlice.reducer; 