import { useSelector } from 'react-redux';
import { translations } from './translations';

export const useTranslation = () => {
    const { language } = useSelector((state) => state.language);
    
    const t = (key) => {
        return translations[language]?.[key] || translations['en'][key] || key;
    };

    return { t, language };
}; 