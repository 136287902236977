import React, { useEffect, useState } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';

const TermsOfService = () => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('/terms-of-service.html')
            .then(response => response.text())
            .then(html => {
                // Extract the body content
                const bodyContent = html.match(/<body[^>]*>([\s\S]*)<\/body>/i)[1];
                setContent(bodyContent);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error loading Terms of Service:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box dangerouslySetInnerHTML={{ __html: content }} />
        </Container>
    );
};

export default TermsOfService;
