import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Get all mistakes
export const getMistakes = createAsyncThunk(
    'mistakes/getMistakes',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.get('/mistakes');
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// Add mistake
export const addMistake = createAsyncThunk(
    'mistakes/addMistake',
    async(mistakeData, { rejectWithValue }) => {
        try {
            const res = await axios.post('/mistakes', mistakeData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to add mistake');
        }
    }
);

// Update mistake
export const updateMistake = createAsyncThunk(
    'mistakes/updateMistake',
    async({ id, mistakeData }, { rejectWithValue }) => {
        try {
            const res = await axios.put(`/mistakes/${id}`, mistakeData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to update mistake');
        }
    }
);

// Delete mistake
export const deleteMistake = createAsyncThunk(
    'mistakes/deleteMistake',
    async(id, { rejectWithValue }) => {
        try {
            await axios.delete(`/mistakes/${id}`);
            return id;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// Get top mistakes
export const getTopMistakes = createAsyncThunk(
    'mistakes/getTopMistakes',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.get('/mistakes/top');
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to load top mistakes');
        }
    }
);

// Get AI suggestions
export const getAiSuggestions = createAsyncThunk(
    'mistakes/getAiSuggestions',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.get('/mistakes/suggestions');
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.msg || 'Failed to load AI suggestions');
        }
    }
);

// Analyze mistakes with AI
export const analyzeMistakes = createAsyncThunk(
    'mistakes/analyzeMistakes',
    async(_, { rejectWithValue }) => {
        try {
            const res = await axios.post('/mistakes/analyze');
            return res.data;
        } catch (err) {
            console.error('Analysis error:', err);
            return rejectWithValue(err.response?.data?.msg || 'Failed to analyze mistakes');
        }
    }
);

const initialState = {
    mistakes: [],
    topMistakes: [],
    aiSuggestions: [],
    lastAnalyzed: null,
    loading: false,
    error: null,
    todaysMistakeCount: 0,
    dailyLimitReached: false
};

const mistakeSlice = createSlice({
    name: 'mistakes',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMistakes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getMistakes.fulfilled, (state, action) => {
                state.loading = false;
                state.mistakes = action.payload.mistakes;
                state.todaysMistakeCount = action.payload.todaysMistakeCount;
                state.dailyLimitReached = action.payload.dailyLimitReached;
                state.error = null;
            })
            .addCase(getMistakes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getAiSuggestions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAiSuggestions.fulfilled, (state, action) => {
                state.loading = false;
                state.aiSuggestions = action.payload.insights || [];
                state.lastAnalyzed = action.payload.lastUpdated;
                state.error = null;
            })
            .addCase(getAiSuggestions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(analyzeMistakes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(analyzeMistakes.fulfilled, (state, action) => {
                state.loading = false;
                state.aiSuggestions = action.payload.insights || [];
                state.lastAnalyzed = new Date().toISOString();
                state.error = null;
            })
            .addCase(analyzeMistakes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addMistake.fulfilled, (state, action) => {
                state.mistakes = [action.payload.mistake, ...state.mistakes];
                state.todaysMistakeCount = action.payload.todaysMistakeCount;
                state.dailyLimitReached = action.payload.dailyLimitReached;
                state.loading = false;
                state.error = null;
            })
            .addCase(addMistake.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addMistake.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMistake.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMistake.fulfilled, (state, action) => {
                state.loading = false;
                state.mistakes = state.mistakes.map(mistake => 
                    mistake._id === action.payload._id ? action.payload : mistake
                );
                state.error = null;
            })
            .addCase(updateMistake.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { clearError } = mistakeSlice.actions;
export default mistakeSlice.reducer;