import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Paper,
    Typography,
    Box,
    Alert
} from '@mui/material';

const Register = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8 }}>
                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Registration Disabled
                    </Typography>

                    <Alert severity="error" sx={{ mb: 3 }}>
                        User registration is currently not available. 
                        Please contact support if you need assistance.
                    </Alert>
                </Paper>
            </Box>
        </Container>
    );
};

export default Register;