import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import axios from 'axios';
import './index.css';
import App from './App';

// Set up axios defaults
const apiUrl = process.env.REACT_APP_API_URL || '';
axios.defaults.baseURL = apiUrl;
if (!apiUrl.endsWith('/api')) {
    axios.defaults.baseURL = `${apiUrl}/api`;
}
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

// Set auth token on initial load
if (localStorage.token) {
    axios.defaults.headers.common['x-auth-token'] = localStorage.token;
}

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();