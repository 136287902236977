import React from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { googleAuth } from '../../features/auth/authSlice';

const GoogleAuth = () => {
    const dispatch = useDispatch();

    const onSuccess = (response) => {
        console.log('Google Auth Response:', response);
        dispatch(googleAuth(response.credential));
    };

    const onError = () => {
        console.log('Google Sign In was unsuccessful.');
    };

    return (
        <div className="google-auth-container">
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onError}
                useOneTap
                theme="filled_blue"
                size="large"
                text="continue_with"
                shape="pill"
            />
        </div>
    );
};

export default GoogleAuth;
