import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Navigation from './Navigation';
import BottomNavigation from './BottomNavigation';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPublicRoute = ['/login', '/register', '/terms', '/privacy'].includes(location.pathname);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: '100vw',
        overflow: 'hidden',
        paddingTop: 'env(safe-area-inset-top)',
        bgcolor: 'background.default'
      }}
    >
      <Navigation />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
          maxWidth: '100vw',
          overflow: 'hidden',
          pb: !isPublicRoute ? (isMobile ? '72px' : '56px') : 0,
          pt: 2
        }}
      >
        {children}
      </Box>
      {!isPublicRoute && <BottomNavigation />}
    </Box>
  );
};

export default Layout;
