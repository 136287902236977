import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Paper, Typography, Container, List, ListItem, ListItemText, Chip, Button, IconButton, Tooltip, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { getWorriesEntries, getTopWorries } from '../features/worries/worriesSlice';
import { getMistakes, getTopMistakes, getAiSuggestions, analyzeMistakes, clearError } from '../features/mistakes/mistakeSlice';
import DashboardCard from '../components/DashboardCard';
import CategoryStats from '../components/dashboard/CategoryStats';
import WorriesAnalysis from '../components/dashboard/WorriesAnalysis';
import MistakesInsight from '../components/dashboard/MistakesInsight';
import { formatDistanceToNow, format } from 'date-fns';
import { useTranslation } from '../features/language/useTranslation';

const DashboardPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, isAuthenticated, loading: authLoading } = useSelector((state) => state.auth);
    const {
        entries = [],
        topWorries = [],
        lastAnalyzed: worriesLastAnalyzed,
        loading: worriesLoading,
        analyzing
    } = useSelector((state) => state.worries);
    const {
        mistakes = [],
        aiSuggestions,
        lastAnalyzed: mistakesLastAnalyzed,
        loading: mistakesLoading,
        error
    } = useSelector((state) => state.mistakes);

    useEffect(() => {
        if (!authLoading && !isAuthenticated) {
            navigate('/login', { replace: true });
        } else if (isAuthenticated && user) {
            dispatch(getWorriesEntries());
            dispatch(getTopWorries(false));
            dispatch(getMistakes());
            dispatch(getTopMistakes());
            dispatch(getAiSuggestions());
        }
    }, [isAuthenticated, authLoading, user, navigate, dispatch]);

    const handleAnalyze = () => {
        dispatch(clearError());
        dispatch(analyzeMistakes())
            .unwrap()
            .then(() => {
                // Не нужно делать дополнительный запрос getAiSuggestions
                // dispatch(getAiSuggestions());
            })
            .catch((error) => {
                console.error('Failed to analyze mistakes:', error);
            });
    };

    const handleRefreshWorries = () => {
        dispatch(getTopWorries(true)); // Force refresh with AI analysis
    };

    const stats = {
        totalMistakes: mistakes?.length || 0,
        totalWorries: entries?.length || 0
    };

    if (mistakesLoading) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography>{t('loading')}</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom component="h1" sx={{ fontWeight: 600 }}>
                    {t('dashboard')}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {t('dashboardDescription')}
                </Typography>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <WorriesAnalysis
                        worries={topWorries}
                        lastAnalyzed={worriesLastAnalyzed}
                        loading={worriesLoading}
                        error={error}
                        onRefresh={handleRefreshWorries}
                        onClearError={() => dispatch(clearError())}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MistakesInsight
                        mistakes={aiSuggestions}
                        lastAnalyzed={mistakesLastAnalyzed}
                        loading={mistakesLoading}
                        error={error}
                        onRefresh={handleAnalyze}
                        onClearError={() => dispatch(clearError())}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CategoryStats mistakes={mistakes || []} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default DashboardPage;