import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Container } from '@mui/material';
import MistakeForm from '../components/mistakes/MistakeForm';
import MistakeList from '../components/mistakes/MistakeList';
import { getMistakes } from '../features/mistakes/mistakeSlice';
import { useTranslation } from '../features/language/useTranslation';

const MistakesPage = () => {
    const [editMistake, setEditMistake] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getMistakes());
    }, [dispatch]);

    const handleEdit = (mistake) => {
        setEditMistake(mistake);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                gap: 4
            }}>
                <Box>
                    <Typography 
                        variant="h4" 
                        component="h1" 
                        gutterBottom
                        sx={{ 
                            fontWeight: 600,
                            color: 'text.primary'
                        }}
                    >
                        {editMistake ? t('editMistake') : t('recordNewMistake')}
                    </Typography>
                    <MistakeForm 
                        editMistake={editMistake} 
                        setEditMistake={setEditMistake} 
                    />
                </Box>

                <Box>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Typography 
                            variant="h5" 
                            component="h2"
                            sx={{ 
                                fontWeight: 600,
                                color: 'text.primary'
                            }}
                        >
                            {t('recentMistakes')}
                        </Typography>
                    </Box>
                    <MistakeList onEdit={handleEdit} />
                </Box>
            </Box>
        </Container>
    );
};

export default MistakesPage;
